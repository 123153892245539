import React from "react";

export const Letter = (props) => {
    return (
        <svg {...props} width={'100%'} height={'100%'} viewBox="0 0 466 532" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M205.098 106.452C196.114 101.841 185.809 100.48 175.934 102.601C166.059 104.721 157.224 110.193 150.931 118.086L0 307.611L33.78 300.177L156.921 145.591C163.21 137.693 172.045 132.216 181.921 130.095C191.798 127.974 202.105 129.339 211.088 133.957L386.907 224.39L420.687 216.956L205.098 106.452Z" fill="#F0EAE3"/>
                <path d="M160.976 164.697L56.8083 295.383L346.579 233.05L350.002 232.344L363.842 229.371L215.031 153.026C206.055 148.447 195.772 147.108 185.921 149.235C176.071 151.362 167.258 156.824 160.976 164.697Z" fill="#F0EAE3"/>
                <path d="M160.976 164.696C167.264 156.797 176.097 151.318 185.972 149.19C195.847 147.063 206.155 148.418 215.143 153.025L363.954 229.37L367.339 228.627L387.056 224.39L211.088 134.106C202.106 129.487 191.798 128.122 181.922 130.244C172.045 132.365 163.21 137.841 156.921 145.74L33.7803 300.326L56.8831 295.383L160.976 164.696Z" fill="#F6DFAB"/>
                <path opacity="0.33" d="M120.387 267.096L115.328 280.291L107.478 299.582L311.758 380.833C322.918 385.219 334.079 383.584 336.497 377.116L410.679 190.899L186.31 101.694L120.387 267.096ZM206.251 328.053C203.61 327.012 201.973 324.931 202.531 323.407L206.474 313.557C207.069 312.033 209.711 311.624 212.352 312.665L245.537 325.86C248.178 326.901 249.815 328.982 249.257 330.543L245.537 340.356C244.905 341.917 242.263 342.289 239.659 341.248L206.251 328.053ZM312.204 336.305C315.924 337.791 318.305 340.765 317.412 342.958L313.469 352.882L311.758 357.156L310.232 360.873L308.558 365.148L308.186 366.003C307.33 368.196 303.573 368.753 299.816 367.266L260.009 351.432C256.289 349.946 253.908 346.972 254.8 344.779L255.135 343.924L256.698 339.761L258.223 336.044L259.897 331.807L264.138 321.734C264.994 319.541 268.751 318.984 272.509 320.471L312.204 336.305Z" fill="#CCCACA"/>
                <path d="M130.768 275.051L122.025 296.98L109.227 329.057L306.959 407.744C312.12 409.793 317.883 409.71 322.983 407.515C328.083 405.319 332.101 401.19 334.154 396.036L457.593 86.3808L240.329 0L130.768 275.051ZM207.442 350.206C206.831 349.968 206.272 349.612 205.799 349.158C205.326 348.703 204.947 348.16 204.684 347.559C204.421 346.958 204.28 346.312 204.268 345.656C204.256 345 204.374 344.349 204.615 343.739L211.125 327.385C211.614 326.157 212.57 325.174 213.784 324.652C214.998 324.129 216.37 324.109 217.599 324.597L249.667 337.346C250.895 337.834 251.879 338.79 252.403 340.002C252.926 341.215 252.946 342.586 252.457 343.813L245.947 360.168C245.458 361.395 244.502 362.378 243.288 362.901C242.074 363.423 240.702 363.443 239.474 362.955L207.442 350.206ZM318.083 337.829C318.949 338.169 319.739 338.677 320.408 339.323C321.077 339.969 321.611 340.741 321.981 341.594C322.351 342.447 322.549 343.364 322.563 344.294C322.577 345.223 322.407 346.146 322.063 347.01L315.479 363.513L312.502 370.612L309.973 377.005L307.145 384.105L306.55 385.554C305.854 387.232 304.538 388.577 302.876 389.311C301.213 390.045 299.331 390.11 297.621 389.494L259.079 374.18C257.335 373.478 255.94 372.114 255.201 370.386C254.462 368.658 254.438 366.708 255.136 364.962L255.694 363.55L258.521 356.451L261.088 350.058L263.916 342.958L270.463 326.455C271.167 324.722 272.526 323.335 274.247 322.597C275.968 321.859 277.91 321.83 279.652 322.515L318.083 337.829Z" fill="#E5E5E5"/>
                <path d="M210.939 167.335L347.882 217.104" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path d="M221.095 139.347L360.084 186.143" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path d="M236.943 104.371L373.923 154.177" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path d="M248.737 70.6953L387.726 117.491" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path d="M195.612 203.724L332.592 253.53" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path d="M184.339 234.686L321.319 284.492" stroke="#201600" strokeWidth="1.58" strokeMiterlimit="10"/>
                <path opacity="0.21" d="M111.868 343.033L130.47 429.822L164.399 475.28L402.495 412.093C413.954 408.673 355.025 385.331 353.016 378.641L406.216 210.934L130.656 271.334L111.868 343.033ZM287.056 436.29C284.34 437.107 281.773 436.513 281.289 434.915L278.239 424.767C277.792 423.169 279.578 421.05 282.294 420.419L316.483 410.197C319.199 409.379 321.766 410.197 322.249 411.572L325.3 421.72C325.783 423.318 323.961 425.436 321.245 426.068L287.056 436.29ZM375.226 377.005C378.946 375.816 382.666 376.708 383.448 378.975L386.535 389.197L387.837 393.583L389.028 397.56L390.367 401.946L390.628 402.838C391.297 405.068 388.73 407.855 384.861 409.008L343.938 421.311C340.218 422.463 336.498 421.608 335.717 419.341L335.456 418.449L334.117 414.063L332.926 410.086L331.624 405.7L328.536 395.478C327.867 393.211 330.471 390.46 334.34 389.308L375.226 377.005Z" fill="#CCCACA"/>
                <path d="M363.816 229.336L349.96 232.317L399.156 460.619L413.013 457.638L363.816 229.336Z" fill="#FAF8F4"/>
                <path d="M367.227 228.627L416.446 456.956L449.445 449.82C452.133 449.246 454.682 448.148 456.945 446.589C459.208 445.031 461.141 443.041 462.634 440.735C464.127 438.429 465.15 435.851 465.644 433.15C466.139 430.448 466.095 427.676 465.516 424.991L420.873 217.104L387.093 224.538L367.227 228.627Z" fill="#FAF8F4"/>
                <path d="M56.8828 295.383L33.78 300.326L0 307.611L44.6431 515.46C45.2163 518.162 46.3199 520.723 47.8899 522.995C49.46 525.268 51.4655 527.207 53.7904 528.7C56.1152 530.193 58.7134 531.211 61.4345 531.694C64.1556 532.178 66.9456 532.118 69.6433 531.517L395.799 461.379L346.58 233.05L56.8828 295.383ZM37.2026 400.831C36.9235 399.555 37.157 398.221 37.8529 397.116C38.5487 396.011 39.6512 395.223 40.9229 394.921L58.1849 391.204C58.8152 391.062 59.4675 391.045 60.1043 391.154C60.7412 391.264 61.3501 391.498 61.8962 391.844C62.4423 392.189 62.9148 392.639 63.2866 393.167C63.6584 393.695 63.9222 394.291 64.0629 394.921L71.5034 428.634C71.7727 429.908 71.535 431.238 70.8407 432.341C70.1464 433.444 69.0499 434.234 67.7832 434.543L50.5584 438.26C49.2823 438.529 47.9516 438.292 46.8477 437.598C45.7437 436.905 44.9532 435.809 44.6431 434.543L37.2026 400.831ZM109.115 485.762C109.339 486.686 109.375 487.645 109.219 488.582C109.063 489.52 108.72 490.416 108.209 491.218C107.699 492.02 107.031 492.71 106.247 493.248C105.463 493.786 104.579 494.16 103.646 494.348L86.2729 498.065L78.8324 499.664L72.0987 501.113L64.6581 502.711L63.1328 503.046C62.2241 503.242 61.2854 503.258 60.3706 503.091C59.4558 502.925 58.5829 502.58 57.8019 502.076C57.0209 501.572 56.3471 500.918 55.8191 500.154C55.2911 499.389 54.9193 498.528 54.7251 497.619L45.9824 457.105C45.7857 456.197 45.7703 455.259 45.9369 454.345C46.1036 453.431 46.4491 452.559 46.9536 451.779C47.4582 450.999 48.1119 450.325 48.8773 449.798C49.6427 449.27 50.5047 448.899 51.414 448.705L52.9393 448.37L60.3798 446.772L67.1135 445.322L74.554 443.724L91.9277 440.007C92.8315 439.792 93.7691 439.756 94.6866 439.903C95.6042 440.05 96.4838 440.376 97.2751 440.863C98.0663 441.35 98.7538 441.987 99.2981 442.74C99.8424 443.492 100.233 444.345 100.447 445.248L109.115 485.762Z" fill="#FAF8F4"/>
                <path d="M349.975 232.31L346.593 233.038L395.79 461.339L399.172 460.612L349.975 232.31Z" fill="#A89E8E"/>
                <path d="M367.22 228.577L363.838 229.305L413.035 457.606L416.417 456.879L367.22 228.577Z" fill="#A89E8E"/>
                <path d="M51.3765 448.555C50.4672 448.749 49.6051 449.121 48.8397 449.648C48.0743 450.176 47.4206 450.849 46.9161 451.629C46.4115 452.41 46.066 453.282 45.8994 454.196C45.7327 455.11 45.7482 456.047 45.9449 456.955L54.6875 497.47C54.8806 498.394 55.2572 499.27 55.795 500.046C56.3328 500.822 57.0209 501.483 57.8186 501.989C58.6163 502.495 59.5075 502.835 60.4393 502.991C61.3712 503.147 62.3249 503.115 63.2441 502.896L64.7694 502.562L53.0506 448.221L51.3765 448.555Z" fill="#C1E88F"/>
                <path d="M67.1236 445.154L60.3953 446.602L72.1091 500.961L78.8374 499.514L67.1236 445.154Z" fill="#C1E88F"/>
                <path d="M91.9278 439.821L74.5542 443.538L86.273 498.065L103.647 494.348C104.556 494.154 105.418 493.783 106.183 493.255C106.949 492.728 107.602 492.055 108.107 491.274C108.612 490.494 108.957 489.622 109.124 488.708C109.29 487.794 109.275 486.856 109.078 485.948L100.447 445.248C100.254 444.328 99.8784 443.456 99.343 442.682C98.8077 441.909 98.1231 441.25 97.3296 440.745C96.5361 440.24 95.6495 439.897 94.722 439.739C93.7944 439.58 92.8444 439.608 91.9278 439.821Z" fill="#C1E88F"/>
                <path d="M60.3859 446.611L52.9302 448.215L64.644 502.574L72.0997 500.97L60.3859 446.611Z" fill="#83B893"/>
                <path d="M74.5966 443.542L67.1409 445.146L78.8547 499.505L86.3104 497.902L74.5966 443.542Z" fill="#83B893"/>
                <path d="M58.186 391.228L40.9833 394.929C38.2918 395.508 36.5796 398.157 37.1591 400.846L44.4176 434.53C44.9971 437.219 47.6488 438.93 50.3403 438.351L67.543 434.651C70.2345 434.072 71.9467 431.422 71.3672 428.733L64.1087 395.049C63.5292 392.36 60.8776 390.65 58.186 391.228Z" fill="#E69433"/>
        </svg>

    )
}