import React from "react";

export const Book = (props) => {
    return (
        <svg {...props} width="100%" height="100%" viewBox="0 0 426 633" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip753450)">
                        <path d="M426 507.63V24.1278C426 10.8812 415.125 0 401.887 0H24.1132C10.8746 0 0 10.8812 0 24.1278V468.363C0 489.652 17.4939 507.157 38.7703 507.157H426V507.63Z" fill="#FF714A"/>
                        <path d="M88.8872 276.286C88.8872 198.698 151.298 136.25 228.838 136.25C306.379 136.25 368.79 198.698 368.79 276.286C368.79 353.4 306.379 416.322 228.838 416.322C151.298 416.322 88.8872 353.4 88.8872 276.286Z" fill="#361F4D"/>
                        <path d="M311.58 244.588H145.624V267.297H311.58V244.588Z" fill="#F2DDC5"/>
                        <path d="M276.591 287.167H180.611V309.876H276.591V287.167Z" fill="#F2DDC5"/>
                        <g opacity="0.3">
                                <path opacity="0.3" d="M130.022 276.287C130.022 221.408 174.466 177.41 228.839 177.41C283.685 177.41 327.656 221.881 327.656 276.287C327.656 331.166 283.212 375.164 228.839 375.164C173.993 375.164 130.022 331.166 130.022 276.287Z" fill="#361F4D"/>
                        </g>
                        <path d="M0 507.156V533.176C0 554.465 17.4939 571.97 38.7703 571.97H425.527V507.156H0Z" fill="#FF5121"/>
                        <path d="M426.001 24.1273V138.143C422.691 138.616 419.382 138.616 416.072 138.616C343.732 138.616 284.631 79.9524 284.631 7.09587C284.631 4.7304 284.631 1.89184 284.631 -0.473633H400.942C415.126 -0.000538617 426.001 10.8806 426.001 24.1273Z" fill="#EDB283"/>
                        <path d="M173.993 7.56951C173.993 79.9529 115.365 139.09 42.5527 139.09C27.4229 139.09 13.2386 136.724 0 131.993V24.1278C0 10.8812 10.8746 0 24.1132 0H173.521C173.521 2.36547 173.993 5.20404 173.993 7.56951Z" fill="#EDB283"/>
                        <path d="M425.999 378.001V507.629H285.103C285.575 435.719 344.204 378.001 416.07 378.001C419.38 378.001 422.69 378.001 425.999 378.001Z" fill="#EDB283"/>
                        <path d="M173.993 507.629H38.7703C17.4939 507.629 0 490.125 0 468.836V385.098C13.2386 380.367 27.8957 378.001 42.5527 378.001C114.42 378.001 173.048 435.719 173.993 507.629Z" fill="#EDB283"/>
                        <path d="M37.3518 0H24.1132C10.8746 0 0 10.8812 0 24.1278V532.704C0 553.52 16.5483 570.552 37.3518 571.498V0Z" fill="#FF5121"/>
                        <path d="M15.6021 532.231C15.6021 545.005 26.0038 555.413 38.7696 555.413H409.924V506.684H41.1337C26.9494 507.157 15.6021 518.511 15.6021 532.231Z" fill="white"/>
                        <path d="M15.6021 532.704C15.6021 545.478 26.0038 555.413 38.7696 555.413H409.924V532.704H15.6021Z" fill="#EDEDED"/>
                        <path d="M399.522 43.0508C399.522 52.9858 391.485 61.0284 381.556 61.0284C371.627 61.0284 363.589 52.9858 363.589 43.0508C363.589 33.1158 371.627 25.0732 381.556 25.0732C391.485 25.0732 399.522 33.1158 399.522 43.0508Z" fill="#FF714A"/>
                        <path d="M399.522 454.169C399.522 464.104 391.485 472.147 381.556 472.147C371.627 472.147 363.589 464.104 363.589 454.169C363.589 444.234 371.627 436.191 381.556 436.191C391.485 436.191 399.522 444.234 399.522 454.169Z" fill="#FF714A"/>
                        <path d="M105.909 43.0508C105.909 52.9858 97.8708 61.0284 87.9418 61.0284C78.0128 61.0284 69.9751 52.9858 69.9751 43.0508C69.9751 33.1158 78.0128 25.0732 87.9418 25.0732C97.8708 25.0732 105.909 33.1158 105.909 43.0508Z" fill="#FF714A"/>
                        <path d="M105.909 454.169C105.909 464.104 97.8708 472.147 87.9418 472.147C78.0128 472.147 69.9751 464.104 69.9751 454.169C69.9751 444.234 78.0128 436.191 87.9418 436.191C97.8708 436.191 105.909 444.234 105.909 454.169Z" fill="#FF714A"/>
                        <path d="M59.5742 532.231V633L84.633 607.926L109.219 633V532.231H59.5742Z" fill="#FF5121"/>
                </g>
                <defs>
                        <clipPath id="clip753450">
                                <rect width="426" height="633" fill="white"/>
                        </clipPath>
                </defs>
        </svg>


    )
}