import React from "react";

export const Toy = (props) => {
    return (
        <svg {...props} width={"100%"} height={'100%'} viewBox="0 0 106 133" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.0499 5.48998C6.72988 12.87 12.6499 20.54 16.7399 20.49L28.5899 6.55998C28.5899 6.55998 19.3799 -1.89002 13.0499 5.48998Z" fill="#D8A46E"/>
            <path d="M24.9996 7.91999C24.8996 7.62999 18.8296 2.38999 14.9996 7.41999C11.1696 12.45 14.9996 18.13 16.7996 17.89C16.7396 17.89 25.0496 8.20999 24.9996 7.91999Z" fill="#D58D5B"/>
            <path d="M10.3299 11.37C10.4601 9.85977 10.932 8.39891 11.7099 7.09786C12.4878 5.79681 13.5512 4.68963 14.8199 3.86C14.6199 3.21 12.8199 4.53 11.1199 7.58C10.2109 9.08279 9.77009 10.8223 9.8538 12.5766C9.93751 14.331 10.5419 16.0206 11.5899 17.43C13.8999 20.43 14.7199 19.96 14.7199 19.96C14.7199 19.96 9.67987 16.79 10.3299 11.37Z" fill="#B5855E"/>
            <path opacity="0.3" d="M17.9996 10.08C13.8796 16 15.7296 20.35 15.7296 20.35L27.6496 5.78997C27.6496 5.78997 22.1696 4.14997 17.9996 10.08Z" fill="#AF754E"/>
            <path d="M97.7698 17.39C101.77 26.23 94.0198 31.97 90.0998 30.78L82.5498 14.14C82.5498 14.14 93.7298 8.55001 97.7698 17.39Z" fill="#D8A46E"/>
            <path d="M85.6695 16.45C85.8395 16.19 93.1295 12.84 95.4295 18.72C97.7295 24.6 92.4295 29.02 90.8195 28.3C90.8195 28.3 85.4895 16.7 85.6695 16.45Z" fill="#D58D5B"/>
            <path d="M98.7697 23.79C99.0592 22.303 99.007 20.7698 98.6173 19.306C98.2275 17.8422 97.5102 16.486 96.5197 15.34C96.8997 14.77 98.2897 16.54 99.0597 19.94C99.5175 21.6347 99.4616 23.4272 98.899 25.0901C98.3364 26.753 97.2925 28.2113 95.8997 29.28C92.8397 31.54 92.1797 30.84 92.1797 30.84C92.1797 30.84 97.8997 29.19 98.7697 23.79Z" fill="#B5855E"/>
            <path opacity="0.3" d="M91.7302 20.44C94.0802 27.28 91.1102 30.94 91.1102 30.94L83.6602 13.66C83.6602 13.66 89.3902 13.59 91.7302 20.44Z" fill="#AF754E"/>
            <path d="M76.55 59.66L27.45 58.94C27.45 58.94 -5.26003 123.18 50.39 123.18C106.04 123.18 76.55 59.66 76.55 59.66Z" fill="#D8A46E"/>
            <path d="M83.6898 84.08C80.9998 97.86 73.2298 118 51.1298 118C27.4798 118 20.7598 97.22 18.8498 84.86C15.7098 102.7 18.9398 123.23 50.3898 123.23C82.5298 123.18 86.2698 102 83.6898 84.08Z" fill="#C69466"/>
            <path d="M75 100.88C75 100.88 75.67 101.53 76.49 100.33C77.31 99.13 76.49 98.68 76.49 98.68C76.49 98.68 75.59 97.68 74.6 99.17C73.61 100.66 75 100.88 75 100.88Z" fill="#C69466"/>
            <path d="M73.1502 99.72C73.1502 99.72 72.2403 98.72 71.2603 100.2C70.2803 101.68 71.6402 101.91 71.6402 101.91C71.6402 101.91 72.3102 102.56 73.1302 101.36C73.9502 100.16 73.1502 99.72 73.1502 99.72Z" fill="#C69466"/>
            <path d="M74.6395 102.41C74.6395 102.41 73.7295 101.41 72.7495 102.9C71.7695 104.39 73.1295 104.61 73.1295 104.61C73.1295 104.61 73.7995 105.26 74.6295 104.06C75.4595 102.86 74.6395 102.41 74.6395 102.41Z" fill="#C69466"/>
            <path d="M21.9994 83.72C21.9994 83.72 21.1294 83.41 20.8794 84.84C20.6294 86.27 21.5394 86.34 21.5394 86.34C21.5394 86.34 22.7594 86.84 23.0594 85.12C23.3594 83.4 21.9994 83.72 21.9994 83.72Z" fill="#C69466"/>
            <path d="M24.17 84C24.17 84 25.39 84.49 25.69 82.77C25.99 81.05 24.63 81.37 24.63 81.37C24.63 81.37 23.76 81.06 23.5 82.49C23.24 83.92 24.17 84 24.17 84Z" fill="#C69466"/>
            <path d="M21.7004 82.17C21.7004 82.17 22.9204 82.66 23.2204 80.95C23.5204 79.24 22.1604 79.55 22.1604 79.55C22.1604 79.55 21.2804 79.23 21.0304 80.67C20.7804 82.11 21.7004 82.17 21.7004 82.17Z" fill="#C69466"/>
            <path d="M84.5998 88.11C83.9098 81.11 81.7198 70.11 80.6498 70.75C80.6498 70.75 84.7398 83.29 84.3498 92.75C83.9598 102.21 81.4498 109 78.7998 112.18C78.7998 112.18 79.4098 113.31 82.1198 107.07C84.8298 100.83 85.2898 95.13 84.5998 88.11Z" fill="#B5855E"/>
            <path d="M57.4099 0.629982C12.4899 -5.68002 11.6599 37.18 11.6599 37.18C11.6599 37.18 8.65986 61.79 47.2199 67.18C85.7799 72.57 89.4699 48.08 89.4699 48.08C89.4699 48.08 102.33 6.99998 57.4099 0.629982Z" fill="#D8A46E"/>
            <path d="M89.4701 48.11C90.6406 43.8772 91.2128 39.5014 91.1701 35.11C86.4501 46.23 73.8501 65.77 44.3801 61.63C12.7501 57.19 13.8801 31.22 16.0001 19.82C13.4884 25.2855 12.0264 31.1744 11.6901 37.18C11.6901 37.18 8.69013 61.79 47.2501 67.18C85.8101 72.57 89.4701 48.11 89.4701 48.11Z" fill="#C69466"/>
            <path d="M23 46.33C23 46.33 22.11 46.61 22.77 47.9C23.43 49.19 24.21 48.7 24.21 48.7C24.21 48.7 25.48 48.36 24.69 46.81C23.9 45.26 23 46.33 23 46.33Z" fill="#C69466"/>
            <path d="M24.8695 45.24C24.8695 45.24 26.1295 44.9 25.3395 43.34C24.5495 41.78 23.6495 42.87 23.6495 42.87C23.6495 42.87 22.7595 43.15 23.4295 44.44C24.0995 45.73 24.8695 45.24 24.8695 45.24Z" fill="#C69466"/>
            <path d="M21.7803 45.26C21.7803 45.26 23.0503 44.92 22.2603 43.37C21.4703 41.82 20.5703 42.89 20.5703 42.89C20.5703 42.89 19.6803 43.17 20.3403 44.47C21.0003 45.77 21.7803 45.26 21.7803 45.26Z" fill="#C69466"/>
            <path d="M87.9995 36C87.9995 36 88.6295 36.69 89.5195 35.54C90.4095 34.39 89.6395 33.9 89.6395 33.9C89.6395 33.9 88.7995 32.9 87.7195 34.27C86.6395 35.64 87.9995 36 87.9995 36Z" fill="#C69466"/>
            <path d="M86.2201 34.72C86.2201 34.72 85.3701 33.72 84.3001 35.09C83.2301 36.46 84.5701 36.82 84.5701 36.82C84.5701 36.82 85.2001 37.51 86.1001 36.36C87.0001 35.21 86.2201 34.72 86.2201 34.72Z" fill="#C69466"/>
            <path d="M87.5295 37.5C87.5295 37.5 86.6895 36.5 85.6195 37.87C84.5495 39.24 85.8895 39.6 85.8895 39.6C85.8895 39.6 86.5195 40.29 87.4195 39.15C88.3195 38.01 87.5295 37.5 87.5295 37.5Z" fill="#C69466"/>
            <path d="M91.5896 30.44C90.9296 24.63 88.1696 17.16 87.1096 18.09C87.1096 18.09 93.1096 26.51 90.5596 43.09C88.0096 59.67 76.9996 66.47 63.2896 67.72C63.2896 67.72 61.9196 69.12 69.5696 67.33C73.3421 66.5114 76.9061 64.9261 80.0404 62.6726C83.1746 60.4191 85.8123 57.5454 87.7896 54.23C91.5996 47.53 92.2596 36.26 91.5896 30.44Z" fill="#B5855E"/>
            <path d="M52.1802 35.28C38.8002 33.4 38.1802 44.14 38.1802 44.14C38.1802 44.14 36.9902 51.51 49.1802 53.22C61.3702 54.93 62.1802 47.52 62.1802 47.52C62.1802 47.52 65.5702 37.16 52.1802 35.28Z" fill="#F0C47C"/>
            <path d="M62.4694 46.43C61.7194 50.92 58.4694 53.94 52.0794 53.43C45.6894 52.92 40.1794 51.43 38.6994 47.08C38.2385 45.9003 38.0786 44.6244 38.2339 43.3674C38.3893 42.1104 38.8552 40.9119 39.5894 39.88C38.7594 39.32 38.0994 41.09 37.7994 43.69C37.4994 46.29 37.7994 50.16 45.2094 52.62C52.6194 55.08 58.8794 54.02 61.2894 50.26C63.6994 46.5 62.4694 46.43 62.4694 46.43Z" fill="#EAB05C"/>
            <path d="M47.8994 35.11C47.8994 35.11 48.9994 38.44 50.7994 38.83C52.5994 39.22 56.7994 37.02 56.8494 36.5C57.0694 34.94 48.4294 33.35 47.8994 35.11Z" fill="#9B7E54"/>
            <path d="M49.6799 34.78C48.5499 34.62 48.6799 35.31 48.6799 35.31C48.6799 35.31 48.5499 35.75 49.4899 35.88C50.4299 36.01 50.3999 35.55 50.3999 35.55C50.3999 35.55 50.8099 34.93 49.6799 34.78Z" fill="#BF9C6D"/>
            <path d="M59.6596 44.77C58.8996 44.27 58.6596 44.77 58.6596 44.77C58.6596 44.77 58.4196 44.9 59.0296 45.35C58.1396 46.05 55.8496 48.48 49.8696 47.64C43.8896 46.8 42.2596 44 41.9196 43C42.6296 42.63 42.3996 42.46 42.3996 42.46C42.3996 42.46 42.2196 41.93 41.3996 42.32C40.5796 42.71 40.8596 43.19 40.8596 43.19C40.8596 43.19 40.8596 43.45 41.4596 43.19C41.5296 44.35 44.1096 47.03 49.7696 48.05C55.6796 49.11 58.7696 46.59 59.3596 45.54C60.0296 45.94 60.0596 45.66 60.0596 45.66C60.0596 45.66 60.4196 45.27 59.6596 44.77Z" fill="#EAB05C"/>
            <path d="M49.4397 49.25C49.0297 49.17 48.6597 48.7 48.4397 48.97C48.4397 48.97 48.3397 49.62 49.2697 49.76C50.1997 49.9 50.5497 49.4 50.4597 49.25C50.3697 49.1 49.8497 49.33 49.4397 49.25Z" fill="#EAB05C"/>
            <path d="M44.0002 24.43C43.4108 24.2914 42.7906 24.3855 42.269 24.6929C41.7473 25.0003 41.3645 25.4972 41.2002 26.08C41.4702 26.8 41.9202 26.18 43.7202 26.43C45.5202 26.68 46.1102 27.03 46.3502 26.8C46.5902 26.57 46.3202 24.75 44.0002 24.43Z" fill="#876545"/>
            <path d="M62.9995 27.1C60.6695 26.77 59.9095 28.45 60.0795 28.74C60.2495 29.03 60.9195 28.86 62.7195 29.11C64.5195 29.36 64.7795 30.11 65.2395 29.46C65.2378 28.855 65.0049 28.2736 64.5885 27.8349C64.172 27.3961 63.6035 27.1332 62.9995 27.1V27.1Z" fill="#876545"/>
            <path d="M94.0596 54.51C78.2896 44.8 73.0596 61.13 73.0596 61.13C73.0596 61.13 60.9296 45.23 50.9996 59.24C41.0696 73.25 72.8796 90.24 72.8796 90.24C72.8796 90.24 109.83 64.22 94.0596 54.51Z" fill="#F6866F"/>
            <path d="M57.9302 57.49C57.5602 56.87 53.9302 57.49 52.3102 59.89C51.3564 61.2697 50.9664 62.962 51.2202 64.62C52.0502 65.56 53.3002 63.21 54.4902 61.45C55.6802 59.69 58.2902 58.12 57.9302 57.49Z" fill="#EFAA9E"/>
            <path d="M55.8502 60.67C55.156 61.5649 54.6893 62.615 54.4902 63.73C54.9502 64.46 56.1602 62.8 56.8602 61.97C57.5602 61.14 59.4902 59.57 59.2302 59.16C58.9702 58.75 56.8902 59.52 55.8502 60.67Z" fill="#EFAA9E"/>
            <path d="M96.2195 59.31C95.6995 57.02 93.1495 55.62 93.1495 55.62C91.9995 55.62 93.1495 57.91 93.5595 59.73C93.9695 61.55 93.8195 65.66 94.6795 65.61C95.5395 65.56 96.7395 61.6 96.2195 59.31Z" fill="#EFAA9E"/>
            <path d="M69.2104 88.21C62.2404 83.93 51.4004 74.89 49.6704 68C47.9404 61.11 52.6204 57.9 52.6204 57.9C52.6204 57.6 51.1104 57.8 49.9704 60.72C48.8304 63.64 48.4604 67 51.4504 72.27C54.4404 77.54 61.7804 83.35 65.5204 86C69.2604 88.65 69.2104 88.21 69.2104 88.21Z" fill="#EF6C56"/>
            <path d="M57.5398 75.07C57.5398 75.07 58.1398 86.95 41.3398 86.89C24.5398 86.83 23.7998 70.82 23.7998 70.82L57.5398 75.07Z" fill="#C69466"/>
            <path d="M27.6496 61.08C27.6496 61.08 32.1496 68.08 37.8396 70.26C43.5296 72.44 56.3896 66.26 57.4896 74.67C58.5896 83.08 47.8896 89.3 33.4396 83.24C18.9896 77.18 23.6896 58.32 27.6496 61.08Z" fill="#D8A46E"/>
            <path d="M39.36 70.27C41.97 70.75 52.36 68.34 55.75 70.82C59.14 73.3 58.85 80.12 53.82 83.56C48.79 87 47.07 85.67 47.07 85.67C55.19 84.58 58.57 78.54 57.15 73.24C55.73 67.94 43.63 70.65 40.05 70.72C36.47 70.79 33.71 67.79 33.71 67.79C33.79 67 36.74 69.79 39.36 70.27Z" fill="#B5855E"/>
            <path d="M54.3901 80.07C54.3901 80.07 54.9401 80.26 54.5001 81.07C54.0601 81.88 53.5901 81.54 53.5901 81.54C53.5901 81.54 52.8101 81.31 53.3301 80.35C53.8501 79.39 54.3901 80.07 54.3901 80.07Z" fill="#C69466"/>
            <path d="M53.2099 79.36C53.2099 79.36 52.4199 79.13 52.9399 78.18C53.4599 77.23 53.9999 77.91 53.9999 77.91C53.9999 77.91 54.5499 78.09 54.1199 78.91C53.6899 79.73 53.2099 79.36 53.2099 79.36Z" fill="#C69466"/>
            <path d="M55.1298 79.43C55.1298 79.43 54.3398 79.2 54.8598 78.24C55.3798 77.28 55.9198 78 55.9198 78C55.9198 78 56.4698 78.19 56.0398 79C55.6098 79.81 55.1298 79.43 55.1298 79.43Z" fill="#C69466"/>
            <path d="M94.0998 60.46C89.1698 64.69 92.3998 72.79 92.3998 72.79C92.3998 72.79 83.1998 80.2 83.5798 81.59C83.5798 81.59 96.3098 80.27 101.38 73.75C106.45 67.23 98.9998 56.23 94.0998 60.46Z" fill="#D8A46E"/>
            <path d="M92.9996 66.62C92.9996 66.62 92.4296 66.76 92.3696 65.85C92.3096 64.94 92.8896 64.97 92.8896 64.97C92.8896 64.97 93.6796 64.75 93.7496 65.83C93.8196 66.91 92.9996 66.62 92.9996 66.62Z" fill="#C69466"/>
            <path d="M94.3603 66.6C94.3603 66.6 95.1503 66.38 95.2203 67.46C95.2903 68.54 94.4603 68.25 94.4603 68.25C94.4603 68.25 93.9003 68.39 93.8403 67.48C93.7803 66.57 94.3603 66.6 94.3603 66.6Z" fill="#C69466"/>
            <path d="M92.7001 67.56C92.7001 67.56 93.4901 67.34 93.5601 68.43C93.6301 69.52 92.8101 69.22 92.8101 69.22C92.8101 69.22 92.2401 69.35 92.1801 68.45C92.1201 67.55 92.7001 67.56 92.7001 67.56Z" fill="#C69466"/>
            <path d="M100 60.8C97.2603 57.8 93.4303 59.8 92.1903 62.48C91.0182 64.8363 90.7069 67.5283 91.3103 70.09C91.8903 72.66 92.3103 73.56 92.6703 73.33C91.3546 70.5861 91.0023 67.4788 91.6703 64.51C92.8703 59.85 98.0603 56.35 102.05 64.11C102 64.11 102.74 63.84 100 60.8Z" fill="#B5855E"/>
            <path d="M8.45013 101.08C8.45013 101.08 -3.54987 105.74 6.07013 122.08C15.6901 138.42 24.9101 129.08 24.9101 129.08C24.9101 129.08 34.1401 122.33 26.1401 108.73C18.1401 95.13 8.45013 101.08 8.45013 101.08Z" fill="#C69466"/>
            <path d="M11.0001 110C11.0001 110 3.39009 113 9.48009 123.33C15.5701 133.66 21.4201 127.74 21.4201 127.74C21.4201 127.74 27.2701 123.46 22.2101 114.84C17.1501 106.22 11.0001 110 11.0001 110Z" fill="#F0C47C"/>
            <path d="M3.08014 115.48C0.560137 106.67 5.18014 103.4 5.18014 103.4C4.89014 102.93 1.18014 103.82 2.07014 112.31C2.96014 120.8 8.68014 127.88 13.1301 130.59C17.5801 133.3 18.4601 131.75 18.4601 131.75C12.6701 132.13 5.60014 124.3 3.08014 115.48Z" fill="#B5855E"/>
            <path d="M7.19962 117.52C6.35962 112.16 10.4996 110.27 10.4996 110.27C10.4996 109.56 8.26962 110.27 7.09962 113.46C5.92962 116.65 6.70962 120.81 10.1896 125.26C13.6696 129.71 14.1896 128.62 14.1896 128.62C12.4296 127.91 7.99962 122.87 7.19962 117.52Z" fill="#EAB05C"/>
            <path d="M99.78 106.89C99.78 106.89 107.68 117.06 91.03 126.16C74.38 135.26 71.29 122.46 71.29 122.46C71.29 122.46 66.84 111.93 80.68 104.36C94.52 96.79 99.78 106.89 99.78 106.89Z" fill="#C69466"/>
            <path d="M93.0002 113.29C93.0002 113.29 98.0002 119.73 87.4602 125.5C76.9202 131.27 75.0002 123.16 75.0002 123.16C75.0002 123.16 72.1702 116.48 81.0002 111.69C89.8302 106.9 93.0002 113.29 93.0002 113.29Z" fill="#F0C47C"/>
            <path d="M97 122C103.69 115.73 101.41 110.55 101.41 110.55C101.89 110.29 104.65 112.98 99.5 119.8C94.35 126.62 85.84 129.8 80.62 129.8C75.4 129.8 75.46 128.07 75.46 128.07C80.22 131.38 90.3 128.28 97 122Z" fill="#B5855E"/>
            <path d="M92.4196 121.64C93.41 120.599 94.0322 119.261 94.1911 117.833C94.35 116.405 94.0369 114.964 93.2996 113.73C93.6696 113.13 95.2096 114.9 94.5796 118.22C93.9496 121.54 91.1496 124.72 85.8696 126.75C80.5896 128.78 80.6896 127.57 80.6896 127.57C82.5896 127.88 88.9996 125.81 92.4196 121.64Z" fill="#EAB05C"/>
            <path d="M11.6098 29.16C10.6098 28.44 9.07985 33.06 9.84985 41.02C10.6198 48.98 12.7498 50.93 13.2998 50.54C13.8498 50.15 10.7998 47.81 10.2998 41.1C9.98541 37.0731 10.4298 33.0229 11.6098 29.16V29.16Z" fill="#B5855E"/>
            <path d="M14.1501 50.87C14.1501 50.87 13.3701 50.6 13.3701 51.7C13.3701 52.8 14.1501 52.48 14.1501 52.48C14.1501 52.48 14.6101 52.54 14.6101 51.68C14.6101 50.82 14.1501 50.87 14.1501 50.87Z" fill="#B5855E"/>
            <path d="M15.5398 52.24C15.5398 52.24 14.7598 51.96 14.7598 53.06C14.7598 54.16 15.5398 53.85 15.5398 53.85C15.5398 53.85 15.9998 53.85 15.9998 53.05C15.9998 52.25 15.5398 52.24 15.5398 52.24Z" fill="#B5855E"/>
            <path d="M96.9995 78.85C93.7249 80.5803 90.193 81.7723 86.5395 82.38C86.1495 83.71 92.1595 82.14 97.2295 79.43C102.3 76.72 104.18 73.72 103.79 73.24C103.4 72.76 102.41 76 96.9995 78.85Z" fill="#B5855E"/>
            <path d="M104.57 71.2C104.57 71.2 103.79 70.92 103.79 72.03C103.79 73.14 104.57 72.81 104.57 72.81C104.57 72.81 105.03 72.87 105.03 72.01C105.03 71.15 104.57 71.2 104.57 71.2Z" fill="#B5855E"/>
            <path d="M105.19 69.17C105.19 69.17 104.41 68.9 104.41 70C104.41 71.1 105.19 70.78 105.19 70.78C105.19 70.78 105.65 70.84 105.65 69.98C105.65 69.12 105.19 69.17 105.19 69.17Z" fill="#B5855E"/>
            <path d="M0.729893 109.22C1.19989 101.81 2.72989 101.49 2.33989 101.1C1.94989 100.71 0.419893 102.1 0.0298926 109.22C-0.360107 116.34 2.93989 123.34 4.02989 122.49C1.68602 118.472 0.54094 113.868 0.729893 109.22V109.22Z" fill="#B5855E"/>
            <path d="M3.40988 101.41C3.40988 101.41 3.86988 101.47 3.86988 100.61C3.86988 99.75 3.40988 99.8 3.40988 99.8C3.40988 99.8 2.62988 99.53 2.62988 100.63C2.62988 101.73 3.40988 101.41 3.40988 101.41Z" fill="#B5855E"/>
            <path d="M5.08957 99C5.08957 99 4.30957 98.73 4.30957 99.83C4.30957 100.93 5.08957 100.61 5.08957 100.61C5.08957 100.61 5.54957 100.67 5.54957 99.81C5.54957 98.95 5.08957 99 5.08957 99Z" fill="#B5855E"/>
        </svg>

    )
}