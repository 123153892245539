import React from "react";

export const IntroDecor = (props) =>{
    return (
        <svg {...props} viewBox="0 0 161 142" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip4560)">
                <path opacity="0.42" d="M75.9053 59.5895C86.2578 57.6094 93.0472 47.6235 91.0699 37.2852C89.0925 26.947 79.0973 20.1713 68.7448 22.1514C58.3923 24.1314 51.6029 34.1174 53.5803 44.4556C55.5576 54.7938 65.5529 61.5695 75.9053 59.5895Z" fill="#71B0CC"/>
                <path d="M72.5015 32.1836L194.313 50.9895L194.72 63.217L70.5094 49.4212L72.5015 32.1836Z" fill="#0388D4"/>
                <path d="M73.9701 49.4711C78.7266 48.5613 81.8461 43.9732 80.9376 39.2232C80.0291 34.4732 75.4366 31.36 70.6801 32.2698C65.9236 33.1795 62.8041 37.7677 63.7126 42.5177C64.6211 47.2677 69.2135 50.3808 73.9701 49.4711Z" fill="#E2D2BC"/>
                <path d="M69.6723 34.7475C74.4308 33.8374 79.0208 36.9489 79.9297 41.7009C80.1647 42.9296 80.136 44.1525 79.8695 45.3085C80.9181 43.5347 81.3522 41.3914 80.934 39.2051C80.0251 34.4531 75.4352 31.3416 70.6766 32.2517C67.1518 32.9446 64.5345 35.6365 63.7728 38.9101C65.0048 36.8202 67.103 35.2389 69.6723 34.7475Z" fill="#E8E3DF"/>
            </g>
            <g clipPath="url(#clip1456)">
                <path opacity="0.42" d="M33.8151 105.273C46.5313 102.562 54.6433 90.0627 51.9338 77.3558C49.2243 64.6488 36.7194 56.5459 24.0032 59.2574C11.287 61.9688 3.17499 74.4679 5.88447 87.1748C8.59396 99.8817 21.0989 107.985 33.8151 105.273Z" fill="#71B0CC"/>
                <path d="M28.8994 71.5406L179.626 91.5956L180.448 106.675L26.894 92.8645L28.8994 71.5406Z" fill="#0388D4"/>
                <path d="M31.1633 92.8365C37.0058 91.5907 40.733 85.8479 39.4881 80.0096C38.2432 74.1712 32.4976 70.4483 26.6551 71.6941C20.8125 72.9399 17.0854 78.6827 18.3303 84.521C19.5752 90.3593 25.3207 94.0823 31.1633 92.8365Z" fill="#E2D2BC"/>
                <path d="M25.4771 74.7779C31.3222 73.5316 37.0647 77.2526 38.3101 83.0934C38.6321 84.6035 38.6288 86.1134 38.3303 87.5469C39.5772 85.3308 40.0564 82.6746 39.4834 79.9874C38.2379 74.1465 32.4954 70.4255 26.6504 71.6719C22.3213 72.6182 19.1639 76.008 18.3101 80.0675C19.7748 77.4565 22.3212 75.4508 25.4771 74.7779Z" fill="#E8E3DF"/>
            </g>
            <defs>
                <clipPath id="clip4560">
                    <rect width="137" height="64" fill="white" transform="translate(50 25.7366) rotate(-10.8278)"/>
                </clipPath>
                <clipPath id="clip1456">
                    <rect width="169" height="79" fill="white" transform="translate(0.978516 64.1669) rotate(-12.0369)"/>
                </clipPath>
            </defs>
        </svg>

    )
}