import React from "react";

export const Paper = (props) => {
    return (
        <svg {...props} width={"100%"} height={'100%'} viewBox="0 0 195 116" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M102.51 93V97.82L4.16992 51.59V46.77L102.51 93ZM4.16992 39.88V44.7L102.53 90.94V86.12L4.16992 39.88ZM4.16992 33.43V37.81L102.56 84.07V79.76L4.16992 33.43ZM4.16992 59.89V64.89L102.43 111V106.69L4.16992 59.89ZM4.16992 53.66V57.82L102.45 104.66V99.86L4.16992 53.66ZM106.17 106.93V111.43L190.4 85.19V80L106.17 106.93ZM106.3 79.83V84.31L190.39 58.08V53.29L106.3 79.83ZM106.3 86.27V91.2L190.39 65V60.06L106.3 86.27ZM106.3 93.16V98.1L190.47 71.86V67L106.3 93.16ZM106.3 100.06V104.97L190.51 77.97V73.84L106.3 100.06Z" fill="#E5E5E5"/>
            <path d="M191.92 46.06L86.7695 0.310009C85.9685 -0.0439162 85.0667 -0.0971722 84.2295 0.160009L2.56952 25.62C2.05318 25.7858 1.57961 26.0632 1.18233 26.4323C0.785059 26.8015 0.473793 27.2535 0.270573 27.7563C0.0673534 28.2591 -0.0228524 28.8004 0.00634201 29.3419C0.0355364 29.8835 0.183417 30.412 0.439515 30.89V67.21L104.15 116L194.15 88V49.31C194.129 48.6114 193.908 47.9334 193.512 47.3571C193.117 46.7807 192.564 46.3305 191.92 46.06ZM102.43 111.06L4.16952 64.84V59.84L102.45 106.68L102.43 111.06ZM102.43 104.68L4.16952 57.82V53.66L102.49 99.86L102.43 104.68ZM102.43 97.83L4.16952 51.59V46.77L102.51 93L102.43 97.83ZM102.43 90.96L4.16952 44.7V39.88L102.54 86.12L102.43 90.96ZM102.43 84.09L4.16952 37.81V33.43L102.58 79.76L102.43 84.09ZM190.26 85.21L106.03 111.45V106.95L190.24 79.95L190.26 85.21ZM190.26 78L106.05 105V100.09L190.22 73.87L190.26 78ZM190.26 71.88L106.22 98.1V93.16L190.39 67L190.26 71.88ZM190.26 64.99L106.26 91.2V86.27L190.37 60.06L190.26 64.99ZM190.26 58.1L106.3 84.31V79.83L190.38 53.29L190.26 58.1ZM190.26 49.39L104.45 76.51L3.66952 29.19L85.2795 3.73001L190.39 49.31H190.44L190.26 49.39ZM87.1595 63.5L86.5895 61.72L157.92 38.72L158.49 40.49L87.1595 63.5ZM78.1595 59.25L77.5995 57.47L148.96 34.78L149.53 36.55L78.1595 59.25ZM69.8195 55.32L69.2495 53.54L140.25 30.99L140.81 32.77L69.8195 55.32ZM61.2895 51.32L60.7295 49.53L131.95 27.38L132.51 29.16L61.2895 51.32ZM53.2895 47.55L52.7395 45.77L123.74 23.77L124.3 25.55L53.2895 47.55ZM44.7695 43.55L44.2195 41.76L115 20L115.55 21.78L44.7695 43.55ZM36.3695 39.33L35.8295 37.55L106.22 16.19L106.76 17.97L36.3695 39.33ZM27.6895 35.5L27.1495 33.71L97.4695 12.37L97.9995 14.16L27.6895 35.5Z" fill="#C6C6C6"/>
            <path d="M3.66992 29.19L104.45 76.51L190.39 49.31L85.2799 3.72998L3.66992 29.19Z" fill="#F2F2F2"/>
            <path d="M158.21 39.38L86.8799 62.41" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M149.22 35.47L77.8496 58.16" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M140.51 31.68L69.5 54.23" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M132.19 28.06L60.9697 50.22" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M123.969 24.49L52.9795 46.45" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M115.229 20.68L44.4492 42.44" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M106.45 16.86L36.0596 38.22" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
            <path d="M97.7194 13.07L27.3994 34.41" stroke="#686868" strokeWidth="2.46" strokeMiterlimit="10"/>
        </svg>

    )
}