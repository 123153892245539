import React from "react";

export const Laptop = (props) => {
    return (
        <svg {...props} width={"100%"} height={'100%'} viewBox="0 0 141 149" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M136.43 44.51L60.1998 0.499985C59.8474 0.249191 59.4387 0.0888423 59.0098 0.0330433C58.5808 -0.0227557 58.1447 0.0277043 57.7398 0.179985H57.6798L57.5398 0.259985L55.5898 1.52999L56.3198 3.99999L56.5298 3.87999V64L138.29 110V113.31L139.29 112.68C139.54 112.525 139.747 112.309 139.891 112.051C140.034 111.794 140.11 111.505 140.11 111.21V50.86C140.036 49.5875 139.664 48.3501 139.025 47.2473C138.386 46.1445 137.497 45.2069 136.43 44.51V44.51Z" fill="#353649"/>
            <path d="M128.82 56L128.96 56.18L139.96 49.73C139.575 48.0663 138.73 46.5448 137.52 45.34L128.8 50.71L128.82 56Z" fill="#494760"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M138.4 113.29V51.93C138.329 50.6578 137.96 49.4202 137.323 48.317C136.685 47.2139 135.797 46.2763 134.73 45.58L58.5001 1.56998C56.5001 0.399983 54.8301 1.34998 54.8301 3.67998V65.05L138.4 113.29Z" fill="#494760"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.5498 61.19L133.53 103.9V49.82L59.5498 7.10999V61.19Z" fill="white"/>
            <path d="M83.2206 46.22L83.0605 46.13V37.32L83.2206 37.41V46.22Z" fill="white"/>
            <path d="M87.2398 50.39L87.0898 50.3V39.64L87.2398 39.73V50.39Z" fill="white"/>
            <path d="M91.2604 51.82L91.1104 51.73V41.96L91.2604 42.05V51.82Z" fill="white"/>
            <path d="M95.2799 55.03L95.1299 54.94V44.29L95.2799 44.38V55.03Z" fill="white"/>
            <path d="M99.3104 53.84L99.1504 53.75V46.61L99.3104 46.7V53.84Z" fill="white"/>
            <path d="M103.33 53.96L103.17 53.87V48.93L103.33 49.02V53.96Z" fill="white"/>
            <path d="M107.35 58.49L107.2 58.4V51.25L107.35 51.34V58.49Z" fill="white"/>
            <path d="M111.371 64.98L111.221 64.89V53.58L111.371 53.66V64.98Z" fill="white"/>
            <path d="M115.39 65.04L115.24 64.95V55.9L115.39 55.99V65.04Z" fill="white"/>
            <path d="M119.421 68.97L119.261 68.88V58.22L119.421 58.31V68.97Z" fill="white"/>
            <path d="M123.44 68.01L123.28 67.92V60.54L123.44 60.63V68.01Z" fill="white"/>
            <path d="M127.461 68.13L127.311 68.04V62.86L127.461 62.95V68.13Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M59.5498 10.73L133.53 53.44V49.96L59.5498 7.25V10.73Z" fill="#F6F6F6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M61.4201 10.63L61.2901 10.56C61.1609 10.468 61.0554 10.3465 60.9824 10.2057C60.9093 10.0648 60.8709 9.90862 60.8701 9.74997C60.8701 9.43997 61.0501 9.29997 61.2901 9.42997L61.4201 9.50997C61.548 9.601 61.6527 9.72085 61.7256 9.85983C61.7986 9.99881 61.8378 10.153 61.8401 10.31C61.8401 10.62 61.6501 10.77 61.4201 10.63Z" fill="#D6D6D6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M63.0002 11.54L62.8702 11.46C62.7423 11.369 62.6377 11.2491 62.5647 11.1101C62.4917 10.9712 62.4525 10.817 62.4502 10.66C62.4502 10.35 62.6402 10.21 62.8702 10.34L63.0002 10.42C63.1281 10.511 63.2327 10.6309 63.3057 10.7699C63.3787 10.9088 63.4179 11.0631 63.4202 11.22C63.4102 11.53 63.2202 11.67 63.0002 11.54Z" fill="#D6D6D6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M64.5607 12.45L64.4307 12.37C64.3029 12.279 64.1982 12.1591 64.1252 12.0202C64.0523 11.8812 64.013 11.727 64.0107 11.57V11.57C64.0107 11.26 64.2007 11.11 64.4307 11.25L64.5607 11.32C64.69 11.412 64.7955 11.5335 64.8685 11.6743C64.9415 11.8152 64.98 11.9714 64.9807 12.13V12.13C65.0007 12.44 64.7907 12.58 64.5607 12.45Z" fill="#D6D6D6"/>
            <path d="M129.481 49.56L128.811 49.17V48.4L129.481 48.79V49.56Z" fill="#D6D6D6"/>
            <path d="M131.54 50.75L130.87 50.36V49.59L131.54 49.98V50.75Z" fill="#D6D6D6"/>
            <path d="M128.03 48.72L125.63 47.33V46.56L128.03 47.95V48.72Z" fill="#D6D6D6"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M132.801 48.7L60.4907 7C60.3661 6.92803 60.2247 6.89014 60.0808 6.89014C59.9369 6.89013 59.7955 6.92801 59.6708 6.99996C59.5462 7.07191 59.4426 7.17541 59.3707 7.30005C59.2987 7.42468 59.2608 7.56607 59.2607 7.71V60.34C59.2607 60.6509 59.3431 60.9563 59.4994 61.225C59.6557 61.4938 59.8805 61.7163 60.1507 61.87L133.151 104C133.236 104.052 133.335 104.08 133.435 104.081C133.535 104.082 133.634 104.056 133.721 104.006C133.807 103.956 133.879 103.884 133.928 103.796C133.978 103.709 134.003 103.61 134.001 103.51V50.73C133.997 50.3159 133.885 49.9101 133.674 49.5536C133.463 49.1971 133.162 48.9026 132.801 48.7V48.7ZM133.491 103.51C133.487 103.532 133.476 103.553 133.46 103.569C133.444 103.585 133.423 103.596 133.401 103.6H133.351L60.3507 61.47C60.154 61.3564 59.9904 61.1932 59.8764 60.9967C59.7623 60.8002 59.7018 60.5772 59.7007 60.35V7.66C59.7007 7.56982 59.7366 7.48334 59.8003 7.41958C59.8641 7.35582 59.9506 7.32 60.0407 7.32C60.0966 7.30602 60.1549 7.30602 60.2107 7.32L132.521 49.07C132.803 49.2356 133.038 49.4722 133.201 49.7563C133.364 50.0405 133.45 50.3623 133.451 50.69L133.491 103.51Z" fill="#353649"/>
            <path d="M138.39 113.29H133.21V115.55L58.21 72.22L54.78 70.22L5.31 98.78V97.78H0V101.84C0.0171499 102.244 0.145817 102.635 0.371743 102.971C0.59767 103.306 0.912034 103.572 1.28 103.74L78.12 148.11C79.3034 148.643 80.5949 148.893 81.8917 148.839C83.1885 148.785 84.4549 148.429 85.59 147.8L136.81 118.11C137.283 117.835 137.675 117.441 137.949 116.967C138.222 116.494 138.368 115.957 138.37 115.41L138.39 113.29Z" fill="#5A5777"/>
            <path d="M138.41 113.29L58.2403 67L54.8103 65L1.81027 95.61C-0.39973 96.88 -0.63973 98.81 1.27027 99.92L78.1103 144.28C79.2925 144.818 80.5846 145.07 81.8822 145.016C83.1799 144.962 84.4467 144.604 85.5803 143.97L138.41 113.29Z" fill="#EBECF7"/>
            <path d="M107.79 99.53L71.62 78.65C70.99 78.28 71.07 77.65 71.8 77.21L73.87 76L112.34 98.21L110.27 99.45C109.892 99.656 109.47 99.7705 109.039 99.7844C108.609 99.7983 108.181 99.7112 107.79 99.53V99.53Z" fill="#3F3F58"/>
            <path d="M54.6806 125.19L33.1606 112.77C32.5206 112.4 32.6006 111.77 33.3406 111.33L45.3406 104.39C45.7185 104.179 46.1403 104.059 46.5726 104.04C47.0049 104.021 47.4356 104.103 47.8306 104.28L69.3506 116.71C69.9906 117.08 69.9106 117.71 69.1706 118.14L57.1706 125.14C56.7888 125.344 56.3643 125.454 55.9316 125.463C55.4989 125.472 55.0703 125.378 54.6806 125.19V125.19Z" fill="#CFD1E5"/>
            <path d="M33.1807 112.22L45.4007 105.22C45.7843 105.023 46.2094 104.92 46.6407 104.92C47.0721 104.92 47.4972 105.023 47.8807 105.22L69.2207 117.54C69.3737 117.624 69.5067 117.74 69.6107 117.88C69.9407 117.5 69.8407 117.03 69.2907 116.71L48.0007 104.33C47.6138 104.138 47.1877 104.038 46.7557 104.038C46.3237 104.038 45.8976 104.138 45.5107 104.33L33.3007 111.39C32.7807 111.68 32.6607 112.12 32.9107 112.48C32.987 112.38 33.0781 112.292 33.1807 112.22Z" fill="#A5A7C6"/>
            <path d="M96.0704 127.74L28.7504 88.87C28.1104 88.5 28.1904 87.87 28.9304 87.43L52.7304 73.69C53.1083 73.4784 53.5308 73.3591 53.9635 73.3418C54.3962 73.3244 54.8268 73.4094 55.2204 73.59L122.55 112.46C123.19 112.83 123.11 113.46 122.37 113.89L98.5604 127.64C98.1818 127.849 97.7596 127.967 97.3273 127.985C96.895 128.002 96.4646 127.918 96.0704 127.74V127.74Z" fill="#FBFAFD"/>
            <path d="M55.3105 75.07L122.46 113.84C123.15 113.44 123.15 112.84 122.46 112.41L55.3105 73.64C54.927 73.4426 54.5018 73.3397 54.0705 73.3397C53.6391 73.3397 53.214 73.4426 52.8305 73.64L28.8305 87.49C28.6834 87.543 28.5562 87.6401 28.4663 87.7681C28.3764 87.896 28.3281 88.0486 28.3281 88.205C28.3281 88.3614 28.3764 88.514 28.4663 88.6419C28.5562 88.7699 28.6834 88.867 28.8305 88.92L52.8305 75.07C53.214 74.8726 53.6391 74.7697 54.0705 74.7697C54.5018 74.7697 54.927 74.8726 55.3105 75.07V75.07Z" fill="#C5C5D3"/>
            <path d="M82.5801 95.83L51.8301 78.07V77.52L54.3101 76.64L85.0601 93.84V94.39L82.5801 95.83Z" fill="#353649"/>
            <path d="M47.1399 86.51L42.1699 83.65L42.1799 83.09L44.6599 82.21L49.6199 84.52V85.08L47.1399 86.51Z" fill="#353649"/>
            <path d="M107.12 121.14L50.8604 88.66L50.8703 88.1L53.3404 87.23L109.6 119.16L109.61 119.71L107.12 121.14Z" fill="#353649"/>
            <path d="M93.7106 118.97L37.3506 86.43V85.88L39.8306 85L96.1906 116.99V117.54L93.7106 118.97Z" fill="#353649"/>
            <path d="M102.3 123.93L97.54 121.18L97.55 120.63L100.02 119.75L104.78 121.95V122.5L102.3 123.93Z" fill="#353649"/>
            <path d="M37.0705 91.85L32.5205 89.22V88.66L35.0005 87.78L39.5505 89.86L39.5605 90.41L37.0705 91.85Z" fill="#353649"/>
            <path d="M43.7102 95.68L39.1602 93.05V92.49L41.6402 91.62L46.1802 93.69L46.1902 94.24L43.7102 95.68Z" fill="#353649"/>
            <path d="M82.5204 118.08L46.4404 97.26L46.4504 96.69L48.9204 95.82L85.0004 116.1V116.65L82.5204 118.08Z" fill="#353649"/>
            <path d="M99.6803 105.7L86.0303 97.82V97.26L88.5103 96.39L102.16 103.71V104.27L99.6803 105.7Z" fill="#353649"/>
            <path d="M116.78 115.57L103.12 107.69L103.13 107.13L105.61 106.26L119.25 113.58L119.26 114.14L116.78 115.57Z" fill="#353649"/>
            <path d="M97.47 126.72L85.75 119.95L85.76 119.39L88.23 118.52L99.95 124.73V125.28L97.47 126.72Z" fill="#353649"/>
            <path d="M92.2507 104.11L89.7607 104.99V105.55L111.951 118.36L114.431 116.92V116.37L92.2507 104.11Z" fill="#353649"/>
            <path d="M88.7703 102.11V101.55L49.5503 79.47L47.0703 80.34V80.9L86.2903 103.55L88.7703 102.11Z" fill="#353649"/>
            <path d="M82.5801 95.27L51.8301 77.52L54.3101 76.08L85.0601 93.84L82.5801 95.27Z" fill="#494760"/>
            <path d="M47.1399 85.96L42.1699 83.09L44.6599 81.66L49.6199 84.52L47.1399 85.96Z" fill="#494760"/>
            <path d="M107.12 120.59L50.8604 88.11L53.3404 86.67L109.61 119.15L107.12 120.59Z" fill="#494760"/>
            <path d="M93.7106 118.42L37.3506 85.88L39.8306 84.44L96.1906 116.98L93.7106 118.42Z" fill="#494760"/>
            <path d="M102.3 123.37L97.54 120.63L100.02 119.19L104.78 121.94L102.3 123.37Z" fill="#494760"/>
            <path d="M37.0705 91.29L32.5205 88.66L35.0105 87.23L39.5605 89.86L37.0705 91.29Z" fill="#494760"/>
            <path d="M43.7102 95.12L39.1602 92.49L41.6402 91.06L46.1902 93.69L43.7102 95.12Z" fill="#494760"/>
            <path d="M82.5204 117.53L46.4404 96.7L48.9204 95.27L85.0004 116.09L82.5204 117.53Z" fill="#494760"/>
            <path d="M99.6803 105.14L86.0303 97.26L88.5103 95.83L102.16 103.71L99.6803 105.14Z" fill="#494760"/>
            <path d="M116.78 115.02L103.12 107.13L105.61 105.7L119.26 113.58L116.78 115.02Z" fill="#494760"/>
            <path d="M97.47 126.16L85.75 119.39L88.23 117.96L99.95 124.73L97.47 126.16Z" fill="#494760"/>
            <path d="M92.2407 103.56L89.7607 104.99L111.951 117.8L114.431 116.37L92.2407 103.56Z" fill="#494760"/>
            <path d="M88.7703 101.56L49.5503 78.91L47.0703 80.34L86.2903 102.99L88.7703 101.56Z" fill="#494760"/>
            <path d="M101.51 137.56L98.0801 139.55V137.82L101.51 135.83V137.56Z" fill="#393955"/>
            <path d="M100.92 136.17V137.32L98.0801 138.97V139.55L101.51 137.56V135.83L100.92 136.17Z" fill="#494863"/>
            <path d="M96.1605 140.54L92.7305 142.53V140.81L96.1605 138.81V140.54Z" fill="#393955"/>
            <path d="M95.5705 139.15V140.31L92.7305 141.96V142.53L96.1605 140.54V138.81L95.5705 139.15Z" fill="#494863"/>
            <path d="M136.511 116.81C136.368 117.031 136.174 117.215 135.947 117.347C135.719 117.479 135.464 117.555 135.201 117.57C134.711 117.57 134.521 117.23 134.761 116.81C134.904 116.589 135.097 116.405 135.325 116.273C135.552 116.141 135.808 116.064 136.071 116.05C136.561 116.05 136.761 116.39 136.511 116.81Z" fill="#393955"/>
            <path d="M136.14 116.06C136.198 116.144 136.229 116.243 136.229 116.345C136.229 116.447 136.198 116.546 136.14 116.63C135.994 116.85 135.798 117.033 135.569 117.165C135.34 117.297 135.084 117.374 134.82 117.39H134.75C134.81 117.453 134.884 117.502 134.965 117.533C135.046 117.564 135.133 117.577 135.22 117.57C135.483 117.556 135.738 117.479 135.966 117.347C136.194 117.215 136.387 117.031 136.53 116.81C136.74 116.42 136.57 116.09 136.14 116.06Z" fill="#494863"/>
            <path d="M112.21 98.13L110.21 99.28C109.834 99.4907 109.414 99.6104 108.983 99.6295C108.553 99.6486 108.123 99.5666 107.73 99.39L71.6002 78.51C71.4101 78.4122 71.2663 78.2433 71.2002 78.04C71.2114 78.1693 71.256 78.2935 71.3297 78.4004C71.4033 78.5074 71.5034 78.5933 71.6202 78.65L107.79 99.53C108.183 99.7066 108.613 99.7886 109.043 99.7695C109.474 99.7504 109.894 99.6307 110.27 99.42L112.34 98.2L112.21 98.13Z" fill="#2E2E3F"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M134.731 45.58L58.5006 1.56997C58.0854 1.24886 57.5754 1.07465 57.0506 1.07465C56.5257 1.07465 56.0157 1.24886 55.6006 1.56997C56.0142 1.37929 56.4711 1.30195 56.9244 1.34588C57.3778 1.38981 57.8113 1.55342 58.1806 1.81997L134.411 45.82C135.478 46.5163 136.366 47.4539 137.003 48.557C137.641 49.6601 138.01 50.8978 138.081 52.17V113.11L138.401 113.29V51.93C138.33 50.6578 137.961 49.4201 137.323 48.317C136.686 47.2139 135.798 46.2763 134.731 45.58V45.58Z" fill="#5A5777"/>
            <path d="M85.6001 143.42C84.4665 144.054 83.1997 144.412 81.902 144.466C80.6044 144.52 79.3123 144.268 78.1301 143.73L1.29007 99.37C0.96073 99.2154 0.67237 98.9854 0.44828 98.6988C0.224191 98.4122 0.0706539 98.0769 7.04625e-05 97.72C-0.0355987 98.1629 0.0675125 98.606 0.295092 98.9877C0.522672 99.3694 0.86343 99.6707 1.27007 99.85L78.1101 144.21C79.2923 144.748 80.5844 145 81.882 144.946C83.1797 144.892 84.4465 144.534 85.5801 143.9L138.39 113.29L137.97 113.05L85.6001 143.42Z" fill="white"/>
        </svg>

    )
}