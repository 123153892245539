import React from "react";

export const Helmet = (props) => {
    return (
        <svg {...props} width={"100%"} height={'100%'} viewBox="0 0 134 127" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5804 86.48C14.2404 79.64 12.3304 63.43 12.5304 54.64C11.0004 54.9 9.46045 55.12 7.91045 55.23C6.99779 55.2798 6.0831 55.2798 5.17045 55.23C3.92193 55.1754 2.7022 55.6151 1.77563 56.4537C0.849055 57.2923 0.290294 58.4623 0.22045 59.71C0.22045 60.64 0.12045 61.71 0.0804496 62.85C-0.096118 69.4848 0.184546 76.1238 0.92045 82.72C1.01485 83.6882 1.45884 84.5888 2.16933 85.2533C2.87982 85.9178 3.80811 86.3006 4.78045 86.33C6.71045 86.39 8.65045 86.44 10.5804 86.48Z" fill="#F56600"/>
            <path d="M131.12 78.91C132.76 77.91 133.26 78.3 133.66 75.35C134.2 68.12 133.46 59.69 132.76 53.43C132.598 52.2848 131.998 51.2474 131.085 50.5366C130.173 49.8257 129.02 49.4971 127.87 49.62C126.143 49.76 124.413 49.89 122.68 50.01C122.187 60.0316 122.224 70.0723 122.79 80.09C124.52 79.59 128.55 80.1 131.12 78.91Z" fill="#F56600"/>
            <path d="M17.4108 107.78C0.0708036 138.64 114.411 124.21 124.131 113.12C128.331 108.33 124.801 100.92 118.991 101.73C102.791 103.97 83.3908 114.46 67.8608 116.25C54.3508 117.8 31.5508 118.25 20.9408 107.65L17.4108 107.78Z" fill="#F56600"/>
            <path d="M23.7106 100.21C20.7106 98.85 14.5506 102.29 17.6406 109.11C19.0006 112 30.0006 116.85 33.0606 117.87C44.3606 121.66 138.341 122.01 121.381 96.44C113.971 93.44 116.431 101.15 109.181 103.72C99.3406 107.2 90.3706 107.31 80.0206 108.84C61.2906 111.61 39.2806 117.73 23.7206 104.73L23.7106 100.21Z" fill="#FECD01"/>
            <path d="M120.5 38.43C117.26 28.96 112.82 20.21 105.8 13.43C95.6003 3.65999 80.0003 -1.93001 54.7303 0.609992C27.0003 3.39999 12.4603 17.52 9.25032 43.08C3.40032 89.64 14.3503 104.82 30.5403 111.27C53.0803 120.27 86.6603 117.61 108.74 108.74C120.12 104.18 122.29 99.74 122.32 91.93C122.34 88.75 129.43 64.63 120.5 38.43ZM96.7503 100.69C93.1261 101.964 89.3991 102.924 85.6103 103.56C82.3151 104.214 78.9691 104.579 75.6103 104.65C73.2403 104.65 70.8703 104.65 68.5003 104.65C67.767 104.65 67.037 104.65 66.3103 104.65C62.7074 104.602 59.1093 104.368 55.5303 103.95C54.0003 103.81 52.5303 103.65 50.9603 103.44C47.7263 103.094 44.5214 102.516 41.3703 101.71C39.0605 101.078 36.7955 100.293 34.5903 99.36C33.1909 98.7898 31.8511 98.083 30.5903 97.25C29.4321 96.4989 28.3139 95.6878 27.2403 94.82C26.1776 94.1255 25.2688 93.2201 24.5703 92.16L24.3803 91.76L24.0403 91.35C23.082 90.1528 22.2852 88.8349 21.6703 87.43C21.4803 87.21 21.2903 86.98 21.1103 86.75C20.2187 85.6776 19.4318 84.5223 18.7603 83.3C17.5203 80.92 16.4003 78.54 16.3603 75.78C16.3603 75.39 16.3603 74.99 16.3603 74.59C16.0842 73.8138 15.952 72.9937 15.9703 72.17C15.971 70.1149 16.2673 68.0706 16.8503 66.1C17.2634 64.758 17.7817 63.4506 18.4003 62.19C19.0812 61.0079 19.8395 59.872 20.6703 58.79C21.9285 57.2301 23.3725 55.8297 24.9703 54.62C27.7632 52.4812 30.8743 50.794 34.1903 49.62C37.0493 48.5266 39.9968 47.6801 43.0003 47.09C46.8403 46.29 50.7303 45.65 54.6303 45.19C59.6303 44.61 64.6903 44.29 69.7303 43.91C76.7303 43.39 83.6103 42.03 90.6403 42.23C93.1871 42.2744 95.7272 42.505 98.2403 42.92C100.614 43.3743 102.927 44.106 105.13 45.1C105.826 45.3712 106.496 45.7059 107.13 46.1C108.39 46.91 105.47 94.54 104.13 95.28C101.92 96.46 99.2203 99.78 96.8703 100.65C93.1303 102 99.1003 99.82 96.7503 100.69Z" fill="#C9C9C9"/>
            <path d="M64.6504 103.42C66.6504 107.17 66.3604 113.22 66.9204 118.31C69.4849 118.723 72.1074 118.597 74.6204 117.94C73.9986 113.001 74.2252 107.992 75.2904 103.13C73.8504 102.83 72.8704 103.18 71.4004 103.13L64.6504 103.42Z" fill="#FECD01"/>
            <path d="M113.14 42.26C111.428 38.8917 108.451 36.3388 104.86 35.16C102.61 34.33 99.8605 33.56 96.5005 32.64C88.719 30.8206 80.8089 29.6037 72.8404 29C52.1104 27.26 23.8404 36.81 18.7804 48.28C18.7804 48.28 7.78045 73.28 17.5804 89.47C25.3404 102.28 51.7305 105.47 64.9005 106.2C86.0505 107.39 109.66 98.65 115.9 83.66C116.06 83.27 125 62.6 113.14 42.26ZM71.2305 99.26C58.3905 98.67 39.4204 98.83 28.9504 89.88C32.5804 84.34 25.6804 64.21 31.3304 59.35C36.0704 55.28 64.2104 52.73 69.6105 52.47C84.5304 51.75 93.5405 49.17 99.2305 61.19C103.31 69.83 103.65 78.58 103.1 88.42C94.4804 94.73 81.7605 99.79 71.2305 99.3V99.26Z" fill="#9B9B9B"/>
            <path d="M103.29 90.31C105.577 88.572 107.693 86.6198 109.61 84.48C109.46 82.59 109.34 80.71 109.23 78.83C108.91 73.07 107.1 56.83 106.41 52.83C102.13 48.58 96.47 41.61 90 38.28C67.09 26.43 38.66 41.4 38.66 41.4C32.4008 45.6995 27.3524 51.5364 24 58.35V59.22C24 59.75 24 60.27 24 60.79C24.93 69.29 26.07 77.79 25.27 86.27C26.3836 87.9606 27.7302 89.4857 29.27 90.8C36.02 96.29 40.58 96.94 47.88 98.61C55.58 100.38 72.83 101.61 81.38 99.8C86.1241 98.7604 90.7244 97.1486 95.08 95C102.73 91 100.48 92.21 103.29 90.31Z" fill="#815055"/>
            <path d="M45.2606 113.33C42.3739 113.679 39.4635 112.906 37.1306 111.17C36.6157 110.727 36.2707 110.119 36.1545 109.45C36.0383 108.781 36.1582 108.092 36.4936 107.502C36.829 106.911 37.3592 106.455 37.9934 106.212C38.6276 105.97 39.3265 105.955 39.9706 106.17C43.0006 107.21 45.3906 109.35 45.2606 113.33Z" fill="#AB71A0"/>
            <path d="M91.0508 108.34C92.8443 106.053 95.4458 104.539 98.3208 104.11C99.0039 104.052 99.6858 104.235 100.248 104.627C100.811 105.019 101.219 105.595 101.401 106.256C101.584 106.917 101.53 107.621 101.249 108.246C100.967 108.871 100.477 109.378 99.8608 109.68C96.9708 111.07 93.7708 111.24 91.0508 108.34C93.0908 105.87 93.7708 111.24 91.0508 108.34Z" fill="#AB71A0"/>
        </svg>

    )
}