import React from "react";

export const TurnLeft = (props) => {
    return (
        <svg {...props} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip1230)">
            <path d="M27.9718 47.3365C35.0009 40.4924 44.4486 36.7012 54.2589 36.788C64.0693 36.8748 73.4484 40.8325 80.3553 47.7999C80.9187 48.3682 81.6848 48.6894 82.4851 48.6928C83.2853 48.6963 84.0541 48.3817 84.6224 47.8183C85.1907 47.2548 85.5119 46.4887 85.5154 45.6885C85.5188 44.8882 85.2042 44.1194 84.6408 43.5511C76.6107 35.4366 65.6954 30.8301 54.2797 30.7377C42.864 30.6454 31.8757 35.0749 23.7153 43.0584L22.9836 48.0271L27.9718 47.3365ZM37.3711 54.1579L17.4454 56.917C16.9814 56.9812 16.5088 56.9366 16.0651 56.7865C15.6213 56.6365 15.2186 56.3852 14.8888 56.0525C14.559 55.7199 14.3112 55.315 14.165 54.87C14.0188 54.4249 13.9782 53.952 14.0464 53.4886L16.9769 33.5874C17.0935 32.7957 17.5198 32.0827 18.1621 31.6053C18.8043 31.1279 19.6099 30.9252 20.4017 31.0418C21.1934 31.1584 21.9063 31.5847 22.3837 32.227C22.8611 32.8692 23.0638 33.6748 22.9472 34.4666L20.6029 50.3875L36.5434 48.1802C36.9359 48.1259 37.3352 48.1494 37.7186 48.2494C38.1021 48.3494 38.462 48.5239 38.778 48.763C39.094 49.0021 39.3598 49.3011 39.5602 49.6429C39.7606 49.9848 39.8917 50.3627 39.9461 50.7552C40.0558 51.5479 39.8462 52.3517 39.3633 52.9899C39.1242 53.3058 38.8252 53.5716 38.4834 53.772C38.1415 53.9725 37.7636 54.1036 37.3711 54.1579Z" fill="white"/>
        </g>
        <defs>
            <clipPath id="clip1230">
                <rect width="64.3704" height="64.3704" fill="white" transform="matrix(-0.710144 0.704056 0.704056 0.710144 45.7122 0)"/>
            </clipPath>
        </defs>
    </svg>
    )
}