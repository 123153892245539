import React from "react";

export const TurnRight = (props) => {
    return(
        <svg {...props} viewBox="0 0 92 92" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip1240)">
                <path d="M63.0607 47.3365C56.0316 40.4924 46.5839 36.7012 36.7736 36.788C26.9632 36.8748 17.5841 40.8325 10.6772 47.7999C10.1138 48.3682 9.34767 48.6894 8.54742 48.6928C7.74717 48.6963 6.97833 48.3817 6.41004 47.8183C5.84174 47.2548 5.52054 46.4887 5.5171 45.6885C5.51365 44.8882 5.82824 44.1194 6.39167 43.5511C14.4218 35.4366 25.337 30.8301 36.7528 30.7377C48.1685 30.6454 59.1568 35.0749 67.3171 43.0584L68.0488 48.0271L63.0607 47.3365ZM53.6614 54.1579L73.587 56.917C74.051 56.9812 74.5236 56.9366 74.9674 56.7865C75.4111 56.6365 75.8139 56.3852 76.1437 56.0525C76.4735 55.7199 76.7213 55.315 76.8675 54.87C77.0137 54.4249 77.0543 53.952 76.9861 53.4886L74.0556 33.5874C73.939 32.7957 73.5127 32.0827 72.8704 31.6053C72.2281 31.1279 71.4225 30.9252 70.6308 31.0418C69.8391 31.1584 69.1261 31.5847 68.6487 32.227C68.1713 32.8692 67.9686 33.6748 68.0852 34.4666L70.4296 50.3875L54.4891 48.1802C54.0966 48.1259 53.6972 48.1494 53.3138 48.2494C52.9304 48.3494 52.5704 48.5239 52.2545 48.763C51.9385 49.0021 51.6727 49.3011 51.4723 49.6429C51.2719 49.9848 51.1408 50.3627 51.0864 50.7552C50.9766 51.5479 51.1863 52.3517 51.6692 52.9899C51.9083 53.3058 52.2073 53.5716 52.5491 53.772C52.8909 53.9725 53.2689 54.1036 53.6614 54.1579Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip1240">
                    <rect width="64.3704" height="64.3704" fill="white" transform="translate(45.3203) rotate(44.7533)"/>
                </clipPath>
            </defs>
        </svg>

    )
}