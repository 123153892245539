import styled from "styled-components";

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const Image = styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`