import React from "react";

export const ZoomIn = (props) => {
    return (
        <svg {...props} viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip98230)">
                <path d="M41.5189 39.1982L29.6693 27.3487C35.0681 20.78 34.7008 11.029 28.5659 4.89425C22.0396 -1.63207 11.4206 -1.63207 4.89425 4.89425C-1.63207 11.4206 -1.63207 22.0396 4.89425 28.5659C11.0274 34.6991 20.7771 35.0704 27.3487 29.6693L39.1982 41.5189C39.8391 42.1597 40.878 42.1597 41.5188 41.5189C42.1597 40.878 42.1597 39.8391 41.5189 39.1982ZM26.2453 26.2453C20.9986 31.492 12.4616 31.4919 7.21492 26.2453C1.9682 20.9985 1.9682 12.4616 7.21492 7.21484C12.4615 1.96836 20.9985 1.96795 26.2453 7.21484C31.4921 12.4616 31.4921 20.9985 26.2453 26.2453Z" fill="white"/>
                <path d="M24.7651 15.0896H18.3716V8.69613C18.3716 7.78985 17.6369 7.05518 16.7306 7.05518C15.8243 7.05518 15.0896 7.78985 15.0896 8.69613V15.0896H8.69613C7.78985 15.0896 7.05518 15.8243 7.05518 16.7306C7.05518 17.6369 7.78985 18.3716 8.69613 18.3716H15.0896V24.7651C15.0896 25.6713 15.8243 26.406 16.7306 26.406C17.6369 26.406 18.3716 25.6713 18.3716 24.7651V18.3716H24.7651C25.6714 18.3716 26.406 17.6369 26.406 16.7306C26.406 15.8243 25.6714 15.0896 24.7651 15.0896Z" fill="white"/>
            </g>
            <defs>
                <clipPath id="clip98230">
                    <rect width="42" height="42" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}